<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 电商
 * @Date: 2020-12-29 18:36:34
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-02-19 17:13:24
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/retailers.vue
-->
<template>
  <div class="user_content">
    <div class="choice">
      <div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="orderType" :options="option1" @change="istap" />
        </van-dropdown-menu>
      </div>
      <div @click="explain">
        订单说明
      </div>
    </div>
    <van-tabs v-model="type" style="border-top: 1px solid #eee;" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div v-if="!list.length" class="ordernull">
          <van-empty
            image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            description="暂无数据"
          />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in list" :key="index">

              <div class="ordernum">订单号: <span id="ordernumber">{{ item.order_sn }}</span> <span
                class="copy"
                @click="copy(item.order_sn)"
              >复制</span></div>
              <div class="ordercen">
                <div class="ordercenImg">
                  <imgLoading :src="item.goods_thumbnail_url" />
                  <!-- <img :src="item.goods_thumbnail_url" alt=""> -->
                </div>
                <div>
                  <div class="title"><img :src="item.icon" alt="">{{ item.goods_name }}</div>
                  <div class="Price">
                    <div style="text-align: left;">
                      <p>付款金额</p>
                      <p>¥{{ item.order_amount }}</p>
                    </div>
                    <div style="text-align: right;">
                      <p>预估成长值</p>
                      <p>{{ item.growth_value }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="orderfoo">
                <div>{{ item.order_create_time }}</div>
                <div>{{ item.specialNotice }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import imgLoading from '@/component/imgLoading'
import mescroll from '@/component/fresh_scroll/mescroll'
import { DropdownItem, DropdownMenu, Empty, List, Tab, Tabs, Toast } from 'vant'
import { orderList } from '@/services/userApi'
import { copyText } from '@/utils/common/utils'

Vue.use(Tab).use(Tabs).use(DropdownMenu).use(DropdownItem).use(Toast).use(Empty).use(List)

export default {
  components: {
    mescroll, imgLoading
  },
  data() {
    return {
      // loading:true,
      mescrollDown: {
        use: false,
        bottom: '0',
        top: '100px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      },
      uid: '',
      type: 0,
      orderType: '7',
      page: 0,
      pageSize: 10,
      option1: [
        { text: '全部订单', value: '' },
        { text: '淘宝订单', value: '7' },
        { text: '京东订单', value: '1' },
        { text: '拼多多订单', value: '0' },
        { text: '唯品会订单', value: '2' },
        { text: '苏宁订单', value: '3' },
        { text: '亚马逊订单', value: '6' },
        { text: '叮当快药订单', value: '11' },
        { text: '美团订单', value: '5' },
        { text: '饿了么订单', value: '12' }
      ],
      tablist: [
        { name: '全部' },
        { name: '待到账' },
        { name: '已到账' }
      ],
      list: []
    }
  },
  created() {
    this.uid = localStorage.getItem('uid')
    this.orderType = this.$route.query.state + ''
  },
  mounted() {
  },
  methods: {
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      this.getinfo(page.num, page.size, (arr) => {
        if (page.num === 1) this.list = []
        this.list = this.list.concat(arr)
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }, () => {
        mescroll.endErr()
      })
    },
    tabkey(t) {
      // this.loading = true
      this.page = 1
      this.list = []
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    getinfo(pageNum, pageSize, successCallback, errorCallback) {
      const prom = {
        mid: window.localStorage.getItem('uid'),
        type: this.type,
        orderType: this.orderType,
        page: pageNum,
        pageSize: pageSize
      }
      orderList(prom).then(res => {
        this.loading = true
        if (Number(res.code) === 200 && res.data.length !== 0) {
          successCallback(res.data.data)
          this.page++
          // this.loading = false
        }
        errorCallback(res.msg)
        this.loading = false
      })
    },
    istap(t) {
      // this.loading = true
      this.list = []
      this.mescroll.resetUpScroll()
    },
    copy(orderSn) {
      copyText(orderSn, () => {
        Toast('复制成功')
      })
    },
    explain() {
      this.$router.push({ path: '/explain' })
    }
  }
}
</script>

<style lang="less" scoped>

.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .choice {
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
      flex: 10;
    }

    & > div:nth-child(2) {
      flex: 2;
      padding: 15px;
      background: #fff;
    }
  }

  .ordernull {
    padding: 13px;
  }

  .orderlist {
    & > ul li {
      background: #FFFFFF;
      padding: 10px;
      margin: 13px;

      .ordernum {
        color: #333333;
        font-size: 11px;
        padding: 10px 0;
        text-align: left;
        border-bottom: 1px solid #ECECEC;

        .copy {
          margin-left: 10px;
          padding: 4px 10px;
          border-radius: 4px;
          border: 1px solid #F6F6F6;
        }
      }

      .ordercen {
        display: flex;

        .ordercenImg {
          max-width: 80px;
          max-height: 80px;
        }

        padding: 10px 0;

        & > div:nth-child(1) {
          img {
            max-width: 80px;
            max-height: 80px;
          }
        }

        & > div:nth-child(2) {
          width: 100%;
          margin: 10px;

          .title {
            display: flex;
            align-content: center;
            color: #333333;
            font-size: 13px;
            text-align: left;
            line-height: 20px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            & > img {
              width: 16px;
              height: 16px;
            }
          }

          .Price {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            & > div {
              & > p:nth-child(1) {
                color: #666666;
                font-size: 12px;
              }

              & > p:nth-child(2) {
                color: #E33F44;
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .orderfoo {
        display: flex;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #999999;
          font-size: 11px;
        }

        & > div:nth-child(2) {
          color: #E33F44;
          font-size: 11px;
        }
      }
    }
  }
}
</style>
